import TYPES from '@/types';
import Vue from 'vue';
import { currencyFormat } from '@/vue-app/utils/currency';
import { requiredRule } from '@/vue-app/utils/form-rules';
import { parseCurrencyToNumber } from '@/vue-app/utils/parse-currency-to-number';

// Application
import {
  SearchAllianzRecurringContributionsQuery,
} from '@/modules/my-investment/allianz/recurring-contributions/application/queries';
import GetSearchByCustomerQuery
  from '@/modules/flagship/investor-goal/search-by-customer/application/queries/get-search-by-customer-query';

// Domain
import { SearchByCustomerDto } from '@/modules/flagship/investor-goal/search-by-customer/domain/dtos/search-by-customer-dto';
import {
  CreateInvestorGoalStateManager,
} from '@/modules/flagship/investor-goal/investor_goal/domain/state/create-investor-goal-state-manager';
import {
  AccessMoreStrategiesStateManager,
} from '@/modules/my-investment/allianz/access-more-strategies/domain/state/access-more-strategies-state-manager';
import {
  StateManager as ContributionsStateManager,
} from '@/modules/my-investment/allianz/recurring-contributions/domain/state/state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

type CustomMessage = {
  message: string;
  class: string;
}

type ActiveRecurrentAmountAmongGoals = {
  label: string;
  recurrent_amount: number;
  tooltip_text: string;
}

export default class ConfigureContributionsWealthViewModel {
  @Inject(TYPES.GET_ALLIANZ_RECURRING_CONTRIBUTIONS_QUERY)
  private readonly get_allianz_recurring_contributions_query!:
    SearchAllianzRecurringContributionsQuery;

  @Inject(TYPES.GET_SEARCH_BY_CUSTOMER_QUERY)
  private readonly get_search_by_customer_query!: GetSearchByCustomerQuery;

  @Inject(TYPES.CREATE_INVESTOR_GOAL_STATE_MANAGER)
  readonly create_investor_goal_state_manager!: CreateInvestorGoalStateManager;

  @Inject(TYPES.ACCESS_MORE_STRATEGIES_STATE_MANAGER)
  private readonly access_more_strategies_manager!: AccessMoreStrategiesStateManager;

  @Inject(TYPES.ALLIANZ_RECURRING_CONTRIBUTIONS_STATE_MANAGER)
  private readonly contributions_state_manager!:
    ContributionsStateManager;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.flagship.flagship-link-goals.step-configure-contributions-wealth';

  private readonly customer_id = sessionStorage.getItem('user_id');

  private readonly error_allianz_account_not_exists = `Error customer_id:${this.customer_id}, not exists`;

  private search_by_customer_dto: SearchByCustomerDto = {
    reload: true,
    associated_product_id: '',
    is_active: true,
  };

  readonly view!: Vue;

  associated_product_id = '';

  is_disabled = false;

  recurrent_assigned_amount = 0;

  recurrent_assigned_amount_formatted = '0.0';

  total_investor_goals_recurrent_assigned_amount = 0;

  max_value_slider = 100000;

  min_value_slider = 500;

  new_recurrent_amount = '0';

  custom_message: CustomMessage = {
    message: '',
    class: '',
  }

  has_active_goal = false;

  active_recurrent_amount_among_goals: Array<ActiveRecurrentAmountAmongGoals> = [];

  recurrent_unassigned_contribution = 0;

  define_contribution_later = false;

  current_recurrent_assigned_amount = 0;

  goal_name = '';

  investor_goal_state = this.create_investor_goal_state_manager.state;

  access_more_strategies_state = this.access_more_strategies_manager.state;

  contributions_state = this.contributions_state_manager.state;

  input_rules = {
    amount: [
      requiredRule,
      (value: string) => (
        (parseFloat(value.replace(/[^0-9.-]/g, '')) >= 500
        || this.define_contribution_later)
        || this.translate_errors('utils.form-rules.minimum_error', { value: '$500.00 MXN' })
      ),
    ],
  };

  public constructor(view: Vue) {
    this.view = view;
  }

  // eslint-disable-next-line max-len
  initialize = async () => {
    this.investor_goal_state.is_loading = true;
    this.setInitialInformation(this.investor_goal_state.associated_product_id);
    await this.loadRecurrentAssignedAmount();
    await this.loadActiveGoals();
    this.setRecurrentUnassignedContributionToZeroIfCustomerHasNoActiveGoals();
    this.setDefaultAmount();
    this.calculateBalanceAmount();
    this.investor_goal_state.is_loading = false;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  translate_errors = (message: string, values?: Values) => this.translator.translate(`${message}`, values);

  getAmountFormatted(amount: number) {
    return currencyFormat(amount);
  }

  setInitialInformation = (product_id: string) => {
    this.associated_product_id = product_id;
    this.active_recurrent_amount_among_goals.length = 0;
    this.search_by_customer_dto.associated_product_id = this.associated_product_id;
  }

  loadActiveGoals = async () => {
    try {
      // eslint-disable-next-line max-len
      const active_goals = await this.get_search_by_customer_query.execute(this.search_by_customer_dto);
      if (active_goals.length) {
        this.has_active_goal = true;
        active_goals.forEach((goal: any) => {
          if (goal.recurrent_assigned_amount > 0) {
            this.active_recurrent_amount_among_goals.push({
              label: (goal.custom_investor_goal) ? goal.custom_investor_goal.goal_name
                : goal.investment_goal_type.label,
              recurrent_amount: goal.recurrent_assigned_amount,
              tooltip_text: (goal.custom_investor_goal) ? this
                .translate('tooltip_custom_goal') : this.translate('tooltip_retirement_goal'),
            });
            this.calculateRecurrentAssignedContribution(goal.recurrent_assigned_amount);
            this.calculateRecurrentUnassignedContribution(goal.recurrent_assigned_amount);
          }
        });
        this.checkIfRecurrentUnassignedContributionIsMoreThanZero();
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_active_goals'));
    }
  }

  loadRecurrentAssignedAmount = async () => {
    try {
      const recurring_contribution = await this.get_allianz_recurring_contributions_query.execute();
      this.recurrent_assigned_amount_formatted = currencyFormat(
        recurring_contribution.total_contribution,
      );
      if (recurring_contribution.contributions.length) {
        const { amount, id, collection_day } = recurring_contribution
          .contributions[0];
        this.investor_goal_state.first_goal_contribution.id = id;
        this.investor_goal_state.first_goal_contribution.collection_day = collection_day;
        this.recurrent_assigned_amount = amount || 0;
        this.current_recurrent_assigned_amount = recurring_contribution.total_contribution || 0;
        this.recurrent_unassigned_contribution = this.recurrent_assigned_amount;
        this.investor_goal_state.first_goal_contribution.set = true;
      }
      if (recurring_contribution.contributions.length === 2) {
        const { id, collection_day } = recurring_contribution
          .contributions[1];
        this.investor_goal_state.second_goal_contribution.id = id;
        this.investor_goal_state.second_goal_contribution.collection_day = collection_day;
        this.investor_goal_state.second_goal_contribution.set = true;
      }
    } catch (error) {
      if (error.message !== this.error_allianz_account_not_exists) {
        this.message_notifier.showErrorNotification(this.translate('errors.get_recurrent_assigned_amount'));
      }
    }
  }

  calculateRecurrentUnassignedContribution = (recurrent_amount: number) => {
    this.recurrent_unassigned_contribution -= recurrent_amount;
  }

  calculateRecurrentAssignedContribution = (recurrent_amount: number) => {
    this.total_investor_goals_recurrent_assigned_amount += recurrent_amount;
  }

  setDefaultAmount = () => {
    if (this.access_more_strategies_state.strategy.id !== '') {
      this.goal_name = this.access_more_strategies_state.strategy.name;
      this.investor_goal_state.first_goal_contribution.amount = 0;
      this.investor_goal_state.first_goal_contribution.amount_field = '0';
    } else {
      this.goal_name = this.investor_goal_state.investor_goal.goal_name;
      this.investor_goal_state.first_goal_contribution.amount = this.investor_goal_state
        .investor_goal.monthly_required_amount;
      this.investor_goal_state.first_goal_contribution.amount_field = this.investor_goal_state
        .investor_goal.monthly_required_amount.toString();
    }
  }

  calculateBalanceAmount = () => {
    const minimum_required_value = this.min_value_slider;
    const maximum_allowed_value = this.max_value_slider - this.contributions_state.item
      .total_contribution;
    let active_goal_amount = (this.investor_goal_state.first_goal_contribution
      .amount_field !== '$0 MXN')
      ? parseCurrencyToNumber(this.investor_goal_state.first_goal_contribution.amount_field)
      : minimum_required_value;
    active_goal_amount = Math.max(active_goal_amount, minimum_required_value);
    active_goal_amount = Math.min(active_goal_amount, maximum_allowed_value);
    this.investor_goal_state.first_goal_contribution.amount = active_goal_amount;
    this.investor_goal_state.first_goal_contribution.amount_field = active_goal_amount.toString();
    if (this.contributions_state.item.contributions.length > 0) {
      this.new_recurrent_amount = (
        this.contributions_state.item.total_contribution + active_goal_amount
      ).toString();
    } else {
      this.new_recurrent_amount = active_goal_amount.toString();
    }
    this.setCustomMessage(
      active_goal_amount,
      this.investor_goal_state.investor_goal.monthly_required_amount,
    );
  }

  // eslint-disable-next-line max-len
  setCustomMessage = (currentAmount: number, recommended_amount: number) => {
    if (currentAmount < recommended_amount) {
      this.custom_message.message = this.translate('message_more_time');
      this.custom_message.class = 'red--text';
    } else if (currentAmount === recommended_amount) {
      this.custom_message.message = this.translate('message_enough_time');
      this.custom_message.class = 'sky-blue--text';
    } else {
      this.custom_message.message = this.translate('message_less_time');
      this.custom_message.class = 'secondary--text';
    }
  }

  setRecurrentUnassignedContributionToZeroIfCustomerHasNoActiveGoals = () => {
    if (!this.has_active_goal) {
      this.recurrent_unassigned_contribution = 0;
    }
  }

  checkIfRecurrentUnassignedContributionIsMoreThanZero = () => {
    if (this.recurrent_unassigned_contribution > 0) {
      this.active_recurrent_amount_among_goals.push({
        label: this.translate('contribution_unassigned_balance'),
        recurrent_amount: this.recurrent_unassigned_contribution,
        tooltip_text: this.translate('tooltip_unassigned_balance'),
      });
      this.total_investor_goals_recurrent_assigned_amount += this.recurrent_unassigned_contribution;
    }
  }

  defineContributionLaterChange = () => {
    this.view.$emit('changeDefineContributionLater', this.define_contribution_later);
    if (this.define_contribution_later) {
      this.investor_goal_state.first_goal_contribution.amount = 0;
      this.investor_goal_state.first_goal_contribution.amount_field = '0';
      this.investor_goal_state.first_goal_contribution.collection_day = 0;
    } else {
      this.calculateBalanceAmount();
    }
  }

  sendNewRecurrentAssignedAmount = async () => {
    this.investor_goal_state.define_contribution_later = this.define_contribution_later;
    if (this.define_contribution_later) {
      if (this.investor_goal_state.first_goal_contribution.id) {
        this.investor_goal_state.first_goal_contribution.set = true;
        this.investor_goal_state.first_goal_contribution.amount = 0;
      }
      if (this.investor_goal_state.second_goal_contribution.id) {
        this.investor_goal_state.second_goal_contribution.set = true;
        this.investor_goal_state.second_goal_contribution.amount = 0;
      }
      this.view.$emit('createAndLinkGoal');
    } else {
      this.investor_goal_state.first_goal_contribution.set = true;
      this.investor_goal_state.total_goal_contribution = this.investor_goal_state
        .first_goal_contribution.amount;
      if (parseCurrencyToNumber(this.new_recurrent_amount) <= 50000
        && this.contributions_state.item.contributions.length < 2) {
        this.view.$emit('nextStep');
        this.view.$emit('nextStep');
      } else {
        this.view.$emit('nextStep');
      }
    }
  }

  goToPrevStep = () => {
    this.view.$emit('prevStep');
  }
}
